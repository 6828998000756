/* eslint-disable no-underscore-dangle */
import { flow, map, replace, sortBy, startsWith, uniqBy } from 'lodash/fp';

import { Maybe } from '../../../types/__codegen__/sanity/graphql';

type ItemWithId = {
  id?: string | Maybe<string>;
  _id?: string | Maybe<string>;
};

const uniqueContentWithDraftPrecedence = <T extends ItemWithId>(items: T[]) =>
  flow(
    sortBy((item: T) => !startsWith('drafts.', item.id || item._id || '')),
    map((item) => {
      const idKey = item.id ? 'id' : '_id';
      return {
        ...item,
        [idKey]: replace('drafts.', '', item[idKey] || ''),
      };
    }),
    uniqBy((item) => item.id || item._id),
  )(items);

export default uniqueContentWithDraftPrecedence;
